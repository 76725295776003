<template>
	<div class=''>
		<!-- 轮播图 -->
		<div class="relative">
			<img :src="bannerImg" class="w-max" style="object-fit: cover;">
			<!-- <el-carousel height="1024px" indicator-position='none'>
        <el-carousel-item v-for="item in 4" :key="item">
         
        </el-carousel-item>
      </el-carousel> -->

			<!-- tab栏 -->
			<tabList :tabList='tabList' @changeTab='changeTab' :tabListId="query.categoryId" ref='tabListDom' />
			<!-- //tab栏 -->
		</div>
		<!-- //轮播图 -->

		<div class="pt146 container flex flex-wrap">
			<div class="w550 mr35 box_item pointer mb40" v-for="(item,i) in newList" :key="i"
				@click="$router.push(`/newsD/${item.id}`)">
				<img :src="item.pcPic" class="w-max h440">
				<div class="w-max h154 flex row-column row-center pl40" style="background: #F2F2F2;">
					<div class="fs18 fw-500">{{item.contentName}}</div>
					<div class="mt16 fs18 color6">{{item.createTime*1000|formatDate}}</div>
				</div>
			</div>
		</div>
		<div class="fs18 color9D mt40 mb45 pointer" style="margin: 0 auto;text-align: center;" @click="btnMore"
			v-show="newList.length>0">
			加载更多
			<i class="el-icon-arrow-down fs28" style="color: #F7B500;"></i>
		</div>
	</div>
</template>

<script>
	import tabList from '@/components/tabList.vue'
	import bannerImgJs from '@/mixin/bannerImg'
	import { getNewsCategory, getNewsList } from '@/api'
	export default {
		name:'news',
		data() {
			return {
				tabList: [],
				bannerType: 7,
				tabList: [],
				query: {
					categoryId: null,
					pageNum: 1,
					pageSize: 6
				},
				//新闻列表
				newList: [],
				count: null,
				// tabListId: null
			};
		},
		mixins: [bannerImgJs],
		components: { tabList },

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			if (this.$route.params.id) {
				this.query.categoryId = this.$route.params.id
			}
			this.getNewsCategory()
		},

		mounted() {},

		methods: {
			//获取新闻分类
			async getNewsCategory() {
				const res = await getNewsCategory()
				this.tabList = res.data
				this.query.categoryId ? '' : this.query.categoryId = res.data[0].id
				this.$refs.tabListDom.active = this.query.categoryId
				this.getNewsList()
			},
			//获取分类列表
			async getNewsList(cd) {
				const res = await getNewsList(this.query)
				this.count = res.data.count
				cd ? this.newList = [...this.newList, ...res.data.obj] : this.newList = res.data.obj
			},
			//分类切换
			changeTab(id) {
				this.query.categoryId = id
				this.getNewsList()
			},
			//加载更多
			btnMore() {
				if (this.newList.length >= this.count) {
					this.$message.warning('暂无更多')
				} else {
					this.getNewsList('cd')
				}
			}
		}
	}
</script>
<style lang='scss' scoped>
	.box_item:nth-child(3n + 0) {
		margin-right: 0 !important;
	}

	.h1024 {
		height: 1024px;
	}
</style>
