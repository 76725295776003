<template>
	<div class="tab_box flex col-center pl76 pr76 fs20">
		<div class="fw-500 flex col-center flex1" v-for="(item,i) in tabList" :key="i" @click="check(item,i)">
			<div class="tab_titel fw-600 flex" :class="{'color-f':item.id==active}">{{item.categoryName}}</div>
			<div class="line_box mr76 ml76" v-if="tabList.length-1!=i"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tabList',
		props: {
			tabList: {},
			tabListId: {}
		},
		data() {
			return {
				active: this.tabListId
			};
		},

		components: {},

		created() {

		},

		mounted() {

		},

		methods: {
			check(item, i) {
				this.active = item.id
				this.$emit('changeTab', item.id)
			}
		}
	}
</script>
<style lang='scss' scoped>
	.tab_box {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 50%);
		height: 100px;
		background: #f7b500;
		z-index: 888;

		.tab_titel {
			white-space: nowrap;
			cursor: pointer;
		}

		.line_box {
			width: 1px;
			height: 70px;
			border: 0.1px solid rgba(0, 0, 0, 0.1);
		}
	}
</style>
